<template>
  <component :is="layout">
    <router-view :layout.sync="layout" />
  </component>
</template>

<script>
import { ScrollReveal } from '@/utils/ScrollReveal.js'
import IouKidApi from '@/services/IouKidApi.js'

export default {
  name: 'App',
  mixins: [ScrollReveal],
  data() {
    return {
      layout: 'div'
    }
  },
  mounted() {
    document.body.classList.add('is-loaded')
  },
  async created() {
    await this.getProfile()
  },
  methods: {
    async getProfile() {
      this.profileLoading = true
      await IouKidApi.api
        .get('auth/profile')
        .then(async () => {})
        .catch(() => {})
      this.profileLoading = false
    }
  }
}
</script>
