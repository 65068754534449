import axios from 'axios'
import store from '@/store/store'
import jsCookie from 'js-cookie'

const IouKidApi = {
  get api() {
    let api = axios.create({
      baseURL: store.state.appConfig.apiUrl,
      withCredentials: true
    })
    api.interceptors.response.use(
      function (response) {
        // Set user store
        let user = jsCookie.get('IouUser')
        if (user) {
          store.commit('setState', {
            prop: 'user',
            value: JSON.parse(user)
          })
        } else {
          store.commit('setState', { prop: 'user', value: null })
        }
        return response
      },
      function (error) {
        return Promise.reject(error)
      }
    )
    return api
  }
}

export default IouKidApi
