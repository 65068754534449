<template>
  <section
    class="signin section"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color'
    ]"
  >
    <div class="container">
      <div
        class="signin-inner section-inner"
        :class="[
          topDivider && 'has-top-divider',
          bottomDivider && 'has-bottom-divider'
        ]"
      >
        <c-section-header
          tag="h1"
          :data="sectionHeader"
          class="center-content"
        />
        <div class="tiles-wrap" v-show="!success">
          <div class="tiles-item">
            <div class="tiles-item-inner">
              <form @submit.prevent="signIn">
                <fieldset>
                  <div class="mb-12">
                    <c-input
                      v-model="signin.email"
                      type="email"
                      label="Email"
                      placeholder="Email"
                      name="email"
                      autocomplete="email"
                      label-hidden
                      required
                      :hint="hint"
                    />
                  </div>
                  <div class="mt-24 mb-32">
                    <c-button color="primary" wide>Sign in</c-button>
                  </div>
                </fieldset>
              </form>
              <div class="signin-bottom has-top-divider">
                <div class="pt-32 text-xs center-content text-color-low">
                  Don't have an account?
                  <router-link to="/signup/" class="func-link"
                    >Sign up</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionProps } from '@/utils/SectionProps.js'
import CSectionHeader from '@/components/sections/partials/SectionHeader.vue'
import CInput from '@/components/elements/Input.vue'
import CButton from '@/components/elements/Button.vue'
import IouKidApi from '@/services/IouKidApi.js'

export default {
  name: 'CLoginForm',
  components: {
    CSectionHeader,
    CInput,
    CButton
  },
  mixins: [SectionProps],
  data() {
    return {
      sectionHeader: {
        title: "Welcome back! Enter your email and we'll send you a login link."
      },
      signin: {},
      success: null,
      hint: null
    }
  },
  methods: {
    async signIn() {
      await IouKidApi.api
        .post('auth/login', this.signin)
        .then(() => {
          this.success = true
          this.sectionHeader.title = 'Check your email for a login link.'
        })
        .catch(error => {
          if (error.response && error.response.status == 404) {
            this.hint = 'Email address not found.'
          } else {
            // eslint-disable-next-line no-console
            console.error(error.message)
          }
        })
    }
  }
}
</script>
