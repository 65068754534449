<template>
  <div class="brand">
    <h1 class="m-0">
      <svg
        :width="width"
        :height="height"
        :fill="color"
        :opacity="opacity"
        @click="$emit('click')"
        viewBox="0 0 78.366 18.241"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-45.362 -63.497)" stroke-width=".26458">
          <path
            d="m50.369 65.176q-0.66559 2.5273-1.119 4.3891-0.44373 1.8521-0.66559 3.0386-0.34727 1.881-0.54019 3.8006-0.18328 1.9196-0.21222 3.8489l-2.2958 0.26045q-0.08682-0.96463-0.13505-1.9678-0.03858-1.0129-0.03858-2.0836 0-2.6817 0.2701-5.672 0.2701-2.9904 0.81029-6.2797zm-1.5048 2.1125-0.34727-0.44373q-0.67524 1.0804-1.2251 2.373-0.54019 1.283-0.96463 2.7685l0.87781 0.37621q0.23151-1.3408 0.6463-2.6045 0.41479-1.2733 1.0129-2.4694z"
          />
          <path
            d="m64.578 72.381q0 1.5241-0.43408 2.8649t-1.3215 2.508q-0.96463 1.2733-2.1801 1.91-1.2058 0.63666-2.6913 0.63666-1.4662 0-2.6817-0.63666-1.2058-0.63666-2.1704-1.91-0.87781-1.1576-1.3119-2.508-0.43408-1.3505-0.43408-2.8649 0-1.5145 0.42444-2.836 0.43408-1.3215 1.283-2.4405 0.93569-1.2251 2.1415-1.8328 1.2058-0.61736 2.6817-0.61736 1.4759 0 2.6913 0.61736 1.2251 0.60772 2.1994 1.8231 0.8971 1.1286 1.3505 2.4502 0.45338 1.3215 0.45338 2.836zm-2.0836 0.90675q0-0.1254 0-0.2508 0-0.13505-0.0096-0.27974l-1.5048 0.11576-0.17363-0.76206 1.5434-0.24116q-0.1254-0.63666-0.35691-1.1865-0.23151-0.55948-0.56913-1.0322l-1.283 1.5338-0.55948-0.45338 1.1286-1.8714q-0.55953-0.48232-1.2348-0.71383-0.67524-0.23151-1.4952-0.22186l0.58842 2.3151-0.68489 0.24116-0.81993-2.3537q-0.72347 0.26045-1.3215 0.7717-0.59807 0.50161-1.09 1.2444l1.5723 1.746-0.56913 0.66559-1.4759-1.5338q-0.30868 0.72347-0.45338 1.3987-0.1447 0.67524-0.1254 1.2926l0.0096 0.36656 1.7074 0.11576v0.84887l-1.6302-0.09646q0.06752 0.47267 0.22186 0.95498 0.16399 0.48231 0.40514 0.96463l1.3215-1.1865 0.54019 0.49196-1.3505 1.4277q0.41479 0.53055 0.90675 0.87781 0.50161 0.34727 1.09 0.53055l0.22186-1.9003 0.68489 0.09646-0.04823 1.9486h0.10611q0.60772 0 1.1865-0.18328 0.58842-0.19293 1.1479-0.59807l-1.1093-2.1511 0.65595-0.38585 1.1383 1.91q0.35691-0.3955 0.62701-0.81029 0.2701-0.41479 0.45338-0.85852l-1.6013-1.3891 0.42444-0.42444 1.4952 0.87781q0.14469-0.40514 0.21222-0.86817 0.07717-0.46302 0.07717-0.98392z"
          />
          <path
            d="m78.912 73.741q0 3.4244-1.5531 5.1318-1.5434 1.7074-4.6592 1.7074-1.5145 0-2.6141-0.38585-1.0997-0.3955-1.7846-1.1768-0.63666-0.72347-0.95498-1.8521t-0.31833-2.6527q0-1.2637 0.28939-3.7717 0.29904-2.5177 0.29904-3.7813 0-0.81994-0.1254-1.4373-0.11576-0.61736-0.35691-1.0418l0.81993-0.36656q0.44373 0.87781 0.70418 1.9968 0.2701 1.119 0.36656 2.4694 0.02894 0.42444 0.03858 1.5916 0.01929 1.1672 0.01929 3.0772 0 1.1768 0.05788 2.0064 0.06752 0.81994 0.19293 1.283 0.2701 0.96463 0.82958 1.4469 0.55948 0.48232 1.4084 0.48232 0.98392 0 1.6592-0.33762 0.68489-0.33762 1.0611-1.0225 0.2701-0.49196 0.40514-1.2444 0.1447-0.75241 0.1447-1.7749 0-0.19292-0.0096-0.46302t-0.01929-0.60772q-0.0096-0.38585-0.01929-0.63666 0-0.26045 0-0.3955l1.4759-0.11576-0.09646-1.1383-1.3987 0.04823-0.04823-1.0804 1.3794-0.06752-0.03858-1.119h-1.3408l-0.04823-1.1286 1.3891 0.09646v-1.0322l-1.3891-0.1254-0.04823-1.1479 4.0032 0.3955q0.13505 3.9357 0.20257 5.9807 0.07717 2.045 0.07717 2.1897zm-6.0675-1.1672q0 0.50161-0.35691 0.85852-0.34727 0.35691-0.83923 0.35691-0.50161 0-0.84887-0.35691-0.33762-0.36656-0.33762-0.85852 0-0.48231 0.33762-0.83923 0.34727-0.35691 0.84887-0.35691 0.49196 0 0.83923 0.35691 0.35691 0.34727 0.35691 0.83923z"
          />
          <path
            d="m95.079 72.603q0 0.34727-0.18328 0.5209-0.17363 0.17363-0.5209 0.17363-0.34727 0-0.53055-0.17363-0.17363-0.17363-0.17363-0.5209 0-0.33762 0.17363-0.51125 0.18328-0.17363 0.53055-0.17363 0.34727 0 0.5209 0.17363 0.18328 0.17363 0.18328 0.51125zm3.463 6.7717-2.8553 2.045-5.7588-9.4534 0.24116 8.3344-3.3376 0.60772 0.70418-16.283 2.1704-0.2508 0.17363 6.5305q1.5434-0.49196 2.7299-1.09 1.1961-0.60772 2.0257-1.283 1.0514-0.84887 1.7267-1.9582 0.67524-1.119 0.97428-2.4887l1.1286 0.33762q-0.21222 1.6495-0.72347 2.8746t-1.3119 2.045q-0.66559 0.68489-1.8231 1.3312-1.1576 0.63666-2.8167 1.2444zm-2.0932-4.9968q0 0.34727-0.17363 0.5209-0.16399 0.16399-0.51125 0.16399-0.33762 0-0.51125-0.16399-0.17363-0.17363-0.17363-0.5209 0-0.34727 0.17363-0.5209 0.17363-0.17363 0.51125-0.17363 0.34727 0 0.51125 0.17363 0.17363 0.17363 0.17363 0.5209zm1.5434 1.8135q0 0.34727-0.16399 0.5209t-0.5209 0.17363q-0.32797 0-0.51125-0.17363-0.17363-0.17363-0.17363-0.5209 0-0.33762 0.17363-0.51125 0.18328-0.17363 0.51125-0.17363 0.35691 0 0.5209 0.17363t0.16399 0.51125zm1.5916 1.8521q0 0.34727-0.17363 0.5209-0.17363 0.16399-0.5209 0.16399-0.33762 0-0.51125-0.16399-0.17363-0.17363-0.17363-0.5209 0-0.33762 0.17363-0.51125 0.17363-0.17363 0.51125-0.17363 0.34727 0 0.5209 0.17363 0.17363 0.17363 0.17363 0.51125z"
          />
          <path
            d="m104.21 69.497-0.24116 0.58842-0.83923-0.43408q-0.13504 0.17363-0.29903 0.27974t-0.36656 0.15434l0.19292 0.791-0.65594 0.09646-0.16399-0.82958q-0.15434-0.02894-0.30868-0.10611-0.1447-0.08682-0.27974-0.19293l-0.63666 0.58842-0.44373-0.51125 0.74277-0.54019q-0.0965-0.18328-0.11576-0.38585-0.01-0.20257 0.0386-0.43408l-0.85852-0.54019 0.31832-0.5209 0.791 0.54019q0.11576-0.14469 0.26045-0.24116 0.15434-0.10611 0.34727-0.16399l-0.16399-1.1383h0.61736l0.0386 1.0611q0.21222 0.02894 0.38585 0.09646t0.32797 0.17363l0.67524-0.54019 0.44373 0.44373-0.7717 0.5209q0.0772 0.10611 0.10611 0.32797 0.0289 0.22186 0.0193 0.55948zm-0.32798 12.241-4.0611-0.5209 1.5048-9.3955h1.8038zm-1.1479-4.9775q0-0.33762-0.17364-0.51125-0.16398-0.17363-0.51125-0.17363-0.35691 0-0.53055 0.17363-0.17363 0.17363-0.17363 0.51125 0 0.34727 0.17363 0.5209 0.17364 0.17363 0.53055 0.17363 0.34727 0 0.51125-0.17363 0.17364-0.17363 0.17364-0.5209zm0 2.6141q0-0.36656-0.26045-0.62701t-0.63666-0.26045q-0.35691 0-0.61736 0.26045t-0.26045 0.62701 0.26045 0.62701q0.26045 0.2508 0.61736 0.2508 0.37621 0 0.63666-0.2508 0.26045-0.26045 0.26045-0.62701z"
          />
          <path
            d="m112.38 65.619q0 0.34727-0.17364 0.5209-0.16398 0.16399-0.51125 0.16399t-0.5209-0.16399q-0.17363-0.17363-0.17363-0.5209 0-0.35691 0.17363-0.53055t0.5209-0.17363 0.51125 0.17363q0.17364 0.17363 0.17364 0.53055zm0.17363 2.2283q0 0.35691-0.17363 0.5209-0.16399 0.16399-0.51126 0.16399-0.33762 0-0.51125-0.16399t-0.17363-0.5209q0-0.32797 0.17363-0.50161 0.17363-0.18328 0.51125-0.18328 0.34727 0 0.51126 0.18328 0.17363 0.17363 0.17363 0.50161zm0.27009 2.3923q0 0.35691-0.17363 0.5209-0.16399 0.16399-0.51125 0.16399-0.34727 0-0.5209-0.16399t-0.17363-0.5209q0-0.34727 0.17363-0.5209 0.17363-0.17363 0.5209-0.17363 0.34726 0 0.51125 0.17363 0.17363 0.17363 0.17363 0.5209zm4.1383-6.7428-1.3794 17.701-1.7267-0.1447-0.0965-2.4212q-0.66559 0.93569-1.5434 1.4084-0.86817 0.47267-1.9486 0.47267-1.7556 0-2.9807-1.2637-1.2251-1.2637-1.2251-3.0289 0-0.95498 0.34726-1.7363 0.35692-0.791 1.0611-1.4084 0.6656-0.58842 1.4855-0.87781 0.81994-0.29904 1.7749-0.29904 0.90675 0 1.5724 0.24116 0.67524 0.24116 1.1383 0.71382l-0.3955-8.4791zm-3.3762 12.579q0-1.6109-0.80064-2.3923-0.80064-0.78135-2.4116-0.78135h-0.18328l-0.22187 1.2444-1.2733-0.24116-0.0965 0.71382 1.2251 0.29904-0.1254 0.86817-1.2347-0.29904-0.1254 0.68489 1.2058 0.34727-0.1447 0.75241-1.1768-0.30868-0.19292 0.80064 1.2251 0.27974-0.19293 1.2251q0.33762 0.14469 0.67524 0.22186 0.33762 0.06752 0.6656 0.06752 0.68488 0 1.254-0.26045 0.56913-0.2701 1.0418-0.81029 0.44373-0.51125 0.6656-1.1093 0.22186-0.60772 0.22186-1.3022z"
          />
          <path
            d="m123.73 63.912-1.1961 12.087-1.4855 0.17363-0.70417-7.1865 0.8971-0.17363v-0.60772l-0.94534 0.21222-0.0868-0.58842 0.98392-0.24116-0.0482-0.62701-0.98392 0.23151-0.0675-0.67524 1.0514-0.26045-0.0482-0.58842-1.0514 0.26045-0.1254-1.1093zm-0.24115 15.309q0 0.73312-0.51126 1.2637-0.5016 0.5209-1.2347 0.5209-0.72347 0-1.254-0.5209-0.5209-0.53055-0.5209-1.2637t0.5209-1.254q0.5209-0.53055 1.254-0.53055t1.2347 0.53055q0.51126 0.5209 0.51126 1.254zm-1.1286-0.24116q0-0.33762-0.23151-0.60772-0.23151-0.27974-0.55949-0.27974-0.32797 0-0.55948 0.27974-0.22187 0.2701-0.22187 0.60772t0.22187 0.61736q0.22186 0.2701 0.55948 0.2701t0.55949-0.2701q0.23151-0.27974 0.23151-0.61736z"
          />
        </g>
      </svg>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'CLogo',
  props: {
    width: { type: Number },
    height: { type: Number },
    color: { type: String },
    opacity: { type: Number, default: 1 }
  }
}
</script>
