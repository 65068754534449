<template>
  <section
    class="features-split section"
    :class="[
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color'
    ]"
  >
    <div class="container">
      <div
        class="features-split-inner section-inner"
        :class="[
          topDivider && 'has-top-divider',
          bottomDivider && 'has-bottom-divider'
        ]"
      >
        <c-section-header :data="sectionHeader" class="center-content" />
        <div
          class="split-wrap"
          :class="[
            invertMobile && 'invert-mobile',
            invertDesktop && 'invert-desktop',
            alignTop && 'align-top'
          ]"
        >
          <div class="split-item" v-for="item in items" :key="item.id">
            <div class="split-item-content center-content-mobile">
              <h3
                :class="`mt-0 mb-16 reveal-from-${
                  item.id % 2 == 1 ? 'left' : 'right'
                }`"
                data-reveal-container=".split-item"
              >
                {{ item.title }}
              </h3>
              <p
                :class="`m-0 reveal-from-${
                  item.id % 2 == 1 ? 'left' : 'right'
                }`"
                data-reveal-delay="100"
                data-reveal-container=".split-item"
              >
                {{ item.text }}
              </p>
            </div>
            <div
              class="reveal-from-bottom"
              data-reveal-container=".split-item"
              data-reveal-delay="200"
              style="margin: auto"
            >
              <c-image
                :src="require(`@/assets/images/${item.image.name}`)"
                :width="item.image.width"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { SectionSplitProps } from '@/utils/SectionProps.js'
import CSectionHeader from '@/components/sections/partials/SectionHeader.vue'
import CImage from '@/components/elements/Image.vue'

export default {
  name: 'CFeaturesSplit',
  components: {
    CSectionHeader,
    CImage
  },
  mixins: [SectionSplitProps],
  data() {
    return {
      sectionHeader: {
        title: 'How it Works',
        paragraph:
          'An IOU Kid account is similar to a bank account except that the parent acts as the bank. The child can check their balance online and easily deposit and withdraw funds through the parent. In addition to making manual balance adjustments, the parent can set up automated allowance and interest.'
      },
      items: [
        {
          id: 1,
          image: { name: 'card.png', width: 350 },
          title: 'Card in Hand',
          text: 'Our card gives kids the feeling of a payment card without the risk. The child can access their balance via a QR code.'
        },
        {
          id: 2,
          image: { name: 'allowance.png', width: 200 },
          title: 'Automated Allowance',
          text: `No need to have cash on hand, use our flexible scheduler to set up your child's allowance.`
        },
        {
          id: 3,
          image: { name: 'growth.jpg', width: 350 },
          title: 'Teaching Opportunites',
          text: 'Encourage your child to save by offering them interest.'
        }
      ]
    }
  }
}
</script>
