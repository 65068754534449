import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'
import Home from './views/Home.vue'
import Signin from './views/Signin.vue'
import Signup from './views/Signup.vue'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin,
      meta: { title: ' - Sign In' }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: { title: ' - Sign Up' }
    }
  ],
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  let title = store.state.appTitle
  if (to.meta.title) {
    title += to.meta.title
  }
  document.title = title
  if (to.path != '/' && store.getters.user) {
    location.replace(store.state.appConfig.appUrl)
  } else next()
})

export default router
