import Vue from 'vue'
import axios from 'axios'
import store from './store/store'

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import App from './App.vue'
import router from './router'

import '@/assets/scss/style.scss'

Vue.config.productionTip = false

axios
  .get('/config.json')
  .then(function (response) {
    store.commit('setConfig', response.data)
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  })
  .catch(error => void error)
