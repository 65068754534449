var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero section center-content",class:[
    _vm.topOuterDivider && 'has-top-divider',
    _vm.bottomOuterDivider && 'has-bottom-divider',
    _vm.hasBgColor && 'has-bg-color',
    _vm.invertColor && 'invert-color'
  ],staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"container-sm"},[_c('div',{staticClass:"hero-inner section-inner pb-0",class:[
        _vm.topDivider && 'has-top-divider',
        _vm.bottomDivider && 'has-bottom-divider'
      ]},[_vm._m(0),_c('div',{staticClass:"hero-figure reveal-from-bottom",attrs:{"data-reveal-delay":"600"}},[_c('c-image',{staticClass:"has-shadow",attrs:{"src":require('@/assets/images/child.jpg'),"alt":"Hero","width":896,"height":502}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero-content"},[_c('h1',{staticClass:"mt-0 mb-12 reveal-from-top",attrs:{"data-reveal-delay":"150"}},[_vm._v(" Allowance in the digital age ")]),_c('div',{staticClass:"container-xs"},[_c('p',{staticClass:"m-0 mb-32 reveal-from-top",attrs:{"data-reveal-delay":"300"}},[_vm._v(" Help your child manage their money while encouraging them to earn and save. ")])])])}]

export { render, staticRenderFns }